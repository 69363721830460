<template>
  <div class="card">
    <a-form :form="form" class="card-header p-5" @submit="addActivity">
      <div class="row">
        <div class="col-md-4">
          <a-form-item>
            <a-select
              v-decorator="[
                'class',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              show-search
              :allowClear="true"
              :placeholder="$t('paiement.listeClasses')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="changeClass"
            >
              <a-select-option
                v-for="classe in classList"
                :key="classe._id"
                :value="classe._id"
              >
                {{ classe.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item>
            <a-select
              v-decorator="[
                'students',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.eleveRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :allowClear="true"
              mode="multiple"
              :placeholder="$t('menu.listeEleves')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="student in studentList"
                :key="student._id"
                :value="student._id"
              >
                {{ student.firstName }} {{ student.lastName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item>
            <a-select
              v-decorator="[
                'activity',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.activiteRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="$t('paiement.listeActivites')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="(v) => selectActivity(v)"
            >
              <a-select-option
                v-for="activtiy in activities"
                :key="activtiy._id"
                :value="activtiy._id"
              >
                {{ activtiy.designation }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :allowClear="false"
              :disabled-date="disabledDate"
              v-decorator="[
                'start',
                {
                  defaultValue: moment(Date.now()),
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.dateDebutRequis'),
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :disabled-date="disabledEndDate"
              v-decorator="[
                'end',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a-button
            type="primary"
            style="width: 100%"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            icon="tags"
            :ghost="false"
          >
            {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>
    </a-form>
    <div class="card-body">
      <a-table
        :loading="tableLeading"
        :data-source="actvitiyFees"
        :columns="columns"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        rowKey="_id"
      >
        <template slot="expandedRowRender" slot-scope="record">
          <div class="d-inline">
            <a-tag
              :color="record.inscription ? 'green' : 'red'"
              v-if="record.activityDetails.inscription > 0"
            >
              <b v-if="record.inscription">Inscription payé</b>
              <b v-else>Inscription Non payé</b>
            </a-tag>
          </div>
          <div
            v-for="(month, key, index) in record.months"
            :key="index"
            class="d-inline"
          >
            <a-tag :color="month ? 'green' : 'red'">
              <b v-if="month"
                >{{ frMonths[parseInt(key.split("-")[1])] }} payé</b
              >
              <b v-else>{{ frMonths[parseInt(key.split("-")[1])] }} Non payé</b>
            </a-tag>
            <div v-if="index % 2 == 1"><br /></div>
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
            <b v-if="record.status == 'payed'">{{ $t("profil.payes") }}</b>
            <b v-else> {{ $t("profil.nonPayes") }}</b>
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            :title="$t('paiement.annulerInscri')"
            @confirm="() => onDelete(record)"
          >
            <a-button type="danger">
              <a-icon type="delete" href="javascript:;" />{{
                $t("action.annuler")
              }}
            </a-button>
          </a-popconfirm>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import store from "store";
import moment from "moment";
const frMonths = {
  1: "Janvier ",
  2: "Février ",
  3: "Mars ",
  4: "Avril ",
  5: "Mai ",
  6: "Juin ",
  7: "Juillet ",
  8: "Août ",
  9: "Septembre ",
  10: "Octobre ",
  11: "November ",
  12: "Décembre ",
};
const actvitiyFeesFormater = (activityFees, studentId, studentList) => {
  const getClassRoom = (studentId, studentList) => {
    try {
      return studentList.filter((s) => s._id == studentId)[0].classRoomName;
    } catch (error) {
      return " ";
    }
  };
  let status = "impayed";
  if (activityFees.inscription) {
    if (!activityFees.activity.once && activityFees.months) {
      status = "payed";
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  return {
    status,
    inscription: activityFees.inscription,
    months: activityFees.months,
    activityDetails: activityFees.activity,
    _id: activityFees._id,
    fullName:
      activityFees.student.firstName + " " + activityFees.student.lastName,
    activity: activityFees.activity.designation,
    class: getClassRoom(studentId, studentList),
  };
};
const dateRange = (startDate, endDate) => {
  var start = moment(startDate).format("YYYY-MM-DD").split("-");
  var end = moment(endDate).format("YYYY-MM-DD").split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};

const monthly = (start, end) => {
  let monthObj = Object();
  dateRange(start, end).forEach((date) => (monthObj[date] = false));
  return monthObj;
};
export default {
  computed: mapState(["settings"]),
  created() {
    this.tableLeading = true;
    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $set: {
              classRoomName: "$classRoom",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoomName",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: 1,
              firstName: 1,
              lastName: 1,
              classRoomName: { $arrayElemAt: ["$classRoomName.name", 0] },
            },
          },
        ],
      })
      .then((res) => {
        const { data } = res;
        this.studentList = data;
        this.studentsData = _.mapValues(_.groupBy(data, "classRoom"), (clist) =>
          clist.map((elem) => _.omit(elem, "classRoom"))
        );
        apiClient
          .post("/students/activity/payment/filter", {
            query: {
              status: "active",
            },
          })
          .then(
            (res) =>
              (this.actvitiyFees = res.data.map((elem) =>
                actvitiyFeesFormater(elem, elem.student._id, this.studentList)
              ))
          )
          .catch((e) => {
            console.log(e);
            this.$message.error(this.$t("error.aucInscri"));
          });
      })
      .catch((e) => this.$message.error(this.$t("error.aucEleve")))
      .finally(() => (this.tableLeading = false));
    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            name: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucClasse")));
    apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            designation: 1,
            start: 1,
            end: 1,
          },
        },
      })
      .then((res) => (this.activities = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));
  },
  data() {
    return {
      frMonths,
      loadingClasse: false,
      columns: [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("accueil.activite"),
          dataIndex: "activity",
          key: "activity",
          scopedSlots: {
            customRender: "activity",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.activity
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("paiement.etat"),
          dataIndex: "status",
          key: "status",
          scopedSlots: {
            customRender: "status",
          },
          filters: [
            { text: "Payé", value: "payed" },
            { text: "Non payé", value: "impayed" },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
          title: this.$t("emploi.classe"),
          dataIndex: "class",
          key: "class",
          scopedSlots: {
            customRender: "class",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.class.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("recette.annulation"),
          dataIndex: "supprimer",
          scopedSlots: { customRender: "operation" },
          //fixed: "right",
        },
      ],
      dateFormat: "DD-MM-YYYY",
      tableLoading: false,
      studentsData: [],
      studentList: [],
      classList: [],
      activities: [],
      actvitiyFees: [],
      loading: false,
      tableLeading: true,
      searchInput: null,
      activitySelected: {
        start: moment(Date.now()),
        end: null,
      },
    };
  },
  methods: {
    moment,
    disabledEndDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      let { start, end } = this.activitySelected;
      if (this.form.getFieldValue("start"))
        start = this.form.getFieldValue("start");
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    disabledDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      const { start, end } = this.activitySelected;
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    selectActivity(val) {
      const activity = [...this.activities].filter((ac) => ac._id == val);
      const { start, end } = activity[0];
      this.activitySelected.start = moment(start);
      this.activitySelected.end = moment(end);
    },
    validateMonths(activityFees, range) {
      let { start, end } = range;
      let result = true;
      if (!end) end = activityFees.activity.end;
      const newMonthly = monthly(start, end);
      const existMonthly = activityFees.months;
      try {
        if (
          Object.keys(existMonthly).forEach((value) => {
            if (Object.keys(newMonthly).includes(value)) result = false;
          })
        )
          result = false;
      } catch {
        result = false;
      }
      return result;
    },
    addActivity(e) {
      this.loadingClasse = true;

      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          values.students.forEach((student) => {
            let already = false;
            const data = {
              start: values.start,
              student: student,
              activity: values.activity,
              inscription: values.inscription,
            };
            apiClient
              .post("/activitiesfees/filter", {
                query: {
                  student: student,
                  activity: values.activity,
                  status: "active",
                },
              })
              .then((res) => {
                res.data.forEach((activityFees) => {
                  if (
                    !this.validateMonths(activityFees, {
                      start: values.start,
                      end: values.end,
                    })
                  )
                    already = true;
                });
                if (already)
                  this.$message.warning(
                    this.$t("error.eleveDejaInscritPourMois")
                  );
                else {
                  apiClient
                    .put("/students/activity/payment", {
                      data: values.end
                        ? {
                            ...data,
                            end: values.end,
                          }
                        : data,
                    })
                    .then((res) => {
                      this.$message.success(this.$t("success.inscriAjout"));
                      this.actvitiyFees.push(
                        actvitiyFeesFormater(
                          res.data,
                          res.data.student._id,
                          this.studentList
                        )
                      );
                    })
                    .catch((e) => {
                      console.error(e.stack);
                      this.$message.error(this.$t("error.inscriEchoue"));
                    });
                }
              });
          });
        }
      });
      //this.form.resetFields();
      this.loadingClasse = false;
    },
    changeClass(value) {
      this.studentList = this.studentsData[value];
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onDelete(key) {
      const { _id } = key;
      apiClient
        .patch("/students/activity/payment/" + _id, {
          data: {
            status: "inactive",
          },
        })
        .then((r) => {
          this.$message.success(this.$t("success.inscriSupp"));
          const dataSource = [...this.actvitiyFees];
          this.actvitiyFees = dataSource.filter((item) => item._id !== _id);
        })
        .catch((r) => {
          this.$message.error(this.$t("error.impoInscriSupp"));
        });
    },
  },
};
</script>
